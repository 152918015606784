// Generated by Framer (ccd740c)

import { addFonts, cx, CycleVariantState, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {XLIlg28vZ: {hover: true}};

const cycleOrder = ["XLIlg28vZ"];

const variantClassNames = {XLIlg28vZ: "framer-v-2p2lnf"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "XLIlg28vZ", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "XLIlg28vZ", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-XISVx", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-2p2lnf", className)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"XLIlg28vZ"} ref={ref} style={{"--border-bottom-width": "3px", "--border-color": "rgb(148, 193, 196)", "--border-left-width": "3px", "--border-right-width": "3px", "--border-style": "solid", "--border-top-width": "3px", backgroundColor: "rgb(26, 27, 27)", borderBottomLeftRadius: 25, borderBottomRightRadius: 25, borderTopLeftRadius: 25, borderTopRightRadius: 25, ...style}} transition={transition} {...addPropertyOverrides({"XLIlg28vZ-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.h3 style={{"--font-selector": "R0Y7RmphbGxhIE9uZS1yZWd1bGFy", "--framer-font-family": "\"Fjalla One\", \"Fjalla One Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-line-height": "1.4em", "--framer-text-color": "var(--extracted-a0htzi)", "--framer-text-transform": "uppercase"}}>Belvedere</motion.h3></React.Fragment>} className={"framer-160jcx0"} fonts={["GF;Fjalla One-regular"]} layoutDependency={layoutDependency} layoutId={"wpK7QrMfi"} style={{"--extracted-a0htzi": "hsl(0, 0%, 72%)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} transition={transition} variants={{"XLIlg28vZ-hover": {"--extracted-a0htzi": "rgb(184, 184, 184)"}}} verticalAlignment={"top"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-XISVx [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-XISVx * { box-sizing: border-box; }", ".framer-XISVx .framer-1di0ogt { display: block; }", ".framer-XISVx .framer-2p2lnf { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; height: 78px; justify-content: space-between; padding: 0px 25px 0px 25px; position: relative; width: 1358px; }", ".framer-XISVx .framer-160jcx0 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-XISVx .framer-q0rm3t { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 129px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 100%; will-change: transform; }", ".framer-XISVx .framer-v-2p2lnf .framer-2p2lnf { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-XISVx .framer-q0rm3t { gap: 0px; } .framer-XISVx .framer-q0rm3t > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-XISVx .framer-q0rm3t > :first-child { margin-left: 0px; } .framer-XISVx .framer-q0rm3t > :last-child { margin-right: 0px; } }", ".framer-XISVx.framer-v-2p2lnf.hover .framer-2p2lnf { height: 240px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 78
 * @framerIntrinsicWidth 1358
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"IMOTlw0cz":{"layout":["fixed","fixed"]}}}
 */
const FramersXeB_1eJw: React.ComponentType<Props> = withCSS(Component, css, "framer-XISVx") as typeof Component;
export default FramersXeB_1eJw;

FramersXeB_1eJw.displayName = "All Works - Row";

FramersXeB_1eJw.defaultProps = {height: 78, width: 1358};

addFonts(FramersXeB_1eJw, [{family: "Fjalla One", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/sXeB_1eJw:default", url: "https://fonts.gstatic.com/s/fjallaone/v13/Yq6R-LCAWCX3-6Ky7FAFnOZwkxgtUb8.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/fjallaone/v13/Yq6R-LCAWCX3-6Ky7FAFnOZwkxgtUb8.ttf", weight: "400"}])